<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isUpdateSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    width="50%"
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-update-sidebar-active', val)"
  ><template #default="{ hide }">
    <!-- Header -->
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 class="mb-0">
        Update Plan
      </h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="hide"
      />
    </div>

    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- Skill Name -->
        <validation-provider
          #default="validationContext"
          name="name"
          rules="required"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="skill.name"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="arName"
          rules="required"
        >
          <b-form-group
            label="Arabic Name"
            label-for="arName"
          >
            <b-form-input
              id="name"
              v-model="skill.arName"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="userType"
          rules="required"
        >
          <b-form-group
            label="User Type"
            label-for="userType"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="skill.type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              :reduce="(val) => val.value"
              :clearable="true"
              input-id="userType"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="price"
          rules="required"
        >
          <b-form-group
            label="Price"
            label-for="price"
          >
            <b-form-input
              id="price"
              v-model="skill.price"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="followUsers"
          rules="required"
        >
          <b-form-group
            label="Follow Users count"
            label-for="followUsers"
          >
            <b-form-input
              id="price"
              v-model="skill.followUsers"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="chatNumber"
          rules="required"
        >
          <b-form-group
            label="Chat number count"
            label-for="chatNumber"
          >
            <b-form-input
              id="price"
              v-model="skill.chatNumber"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="displayOrder"
          rules="required"
        >
          <b-form-group
            label="Display order"
            label-for="displayOrder"
          >
            <b-form-input
              id="price"
              v-model="skill.displayOrder"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Skill Slug -->
        <validation-provider
          #default="validationContext"
          name="content"
          rules="required"
        >
          <b-form-group
            label="Content"
            label-for="content"
          >
            <quill-editor
              v-model="skill.content"
              required
            />

            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="arContent"
          rules="required"
        >
          <b-form-group
            label="Arabic Content"
            label-for="arContent"
          >
            <quill-editor
              v-model="skill.arContent"
              required
            />

            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="currency"
          rules="required"
        >
          <b-form-group
            label="Currency"
            label-for="currency"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="skill.currency"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="getCountriesCurrency"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="currency"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="auction"
          rules="required"
        >
          <b-form-group
            label=""
            label-for="contactDetails"
            :state="getValidationState(validationContext)"
          >
            <b-form-checkbox
              v-model="skill.contactDetails"
              checked="false"
              name="check-button"

              switch
              inline
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              input-id="contactDetails"
            >
              Contact Details
            </b-form-checkbox>
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="auction"
          rules="required"
        >
          <b-form-group
            label=""
            label-for="Auction"
            :state="getValidationState(validationContext)"
          >
            <b-form-checkbox
              v-model="skill.auction"
              checked="false"
              name="check-button"

              switch
              inline
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              input-id="auction"
            >
              Auction
            </b-form-checkbox>
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="timeLine"
          rules="required"
        >
          <b-form-group
            label=""
            label-for="timeLine"
            :state="getValidationState(validationContext)"
          >
            <b-form-checkbox
              v-model="skill.timeLine"
              checked="false"
              name="check-button"

              switch
              inline
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              input-id="timeLine"
            >
              TimeLine
            </b-form-checkbox>
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="timeLineSuggestions"
          rules="required"
        >
          <b-form-group
            label=""
            label-for="timeLine"
            :state="getValidationState(validationContext)"
          >
            <b-form-checkbox
              v-model="skill.timeLineSuggestions"
              checked="false"
              name="check-button"

              switch
              inline
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              input-id="timeLineSuggestions"
            >
              TimeLine Suggestions
            </b-form-checkbox>
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="newsLetter"
          rules="required"
        >
          <b-form-group
            label=""
            label-for="newsLetter"
            :state="getValidationState(validationContext)"
          >
            <b-form-checkbox
              v-model="skill.newsLetter"
              checked="false"
              name="check-button"

              switch
              inline
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              input-id="newsLetter"
            >
              NewsLetter
            </b-form-checkbox>
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="forum"
          rules="required"
        >
          <b-form-group
            label=""
            label-for="forum"
            :state="getValidationState(validationContext)"
          >
            <b-form-checkbox
              v-model="skill.forum"
              checked="false"
              name="check-button"

              switch
              inline
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              input-id="forum"
            >
              Forum
            </b-form-checkbox>
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="events"
          rules="required"
        >
          <b-form-group
            label=""
            label-for="events"
            :state="getValidationState(validationContext)"
          >
            <b-form-checkbox
              v-model="skill.events"
              checked="false"
              name="check-button"

              switch
              inline
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              input-id="events"
            >
              Events
            </b-form-checkbox>
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          #default="validationContext"
          name="ads"
          rules="required"
        >
          <b-form-group
            label=""
            label-for="ads"
            :state="getValidationState(validationContext)"
          >
            <b-form-checkbox
              v-model="skill.ads"
              checked="false"
              name="check-button"

              switch
              inline
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              input-id="ads"
            >
              Ads
            </b-form-checkbox>
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-row>
          <b-col cols="9">
            <validation-provider
              #default="validationContext"
              name="image"
              rules=""
            >
              <b-form-group
                label="Image"
                label-for="currency"
                :state="getValidationState(validationContext)"
              >
                <b-form-file
                  v-model="skill.image"
                  accept="image/jpeg, image/png, image/gif"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  :multiple="false"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="3">
            <b-img
              :src="skill.image"
              height="80"
              width="80"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="9">
            <validation-provider
              #default="validationContext"
              name="badge"
              rules=""
            >
              <b-form-group
                label="Badge"
                label-for="badge"
                :state="getValidationState(validationContext)"
              >
                <b-form-file
                  v-model="skill.badge"
                  accept="image/jpeg, image/png, image/gif"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  :multiple="false"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="3">
            <b-img
              :src="skill.badge"
              height="80"
              width="80"
            />
          </b-col>
        </b-row>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Update Plan
          </b-button>
          <b-button
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
        </b-row></b-form>
    </validation-observer>
  </template></b-sidebar>
</template>
<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BRow,
  BFormTextarea,
  BCol,
  BImg,
} from 'bootstrap-vue'
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import useSkillsList from './usePlansList'

import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    quillEditor,
    BFormFile,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormCheckbox,
    BImg,
    BFormTextarea,

  },
  props: {
    isUpdateSidebarActive: {
      required: true,
      type: Boolean,
    },
    skill: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      getCountriesCurrency: 'country/getCountriesCurrency',

    }),
  },
  created() {
    this.fetchCountries()
  },
  methods: {
    ...mapActions({
      fetchCountries: 'country/retreiveCountries',
    }),
  },
  setup(props, { emit }) {
    const { skillStatuses, updateSkill } = useSkillsList()

    const { refFormObserver, getValidationState, resetForm } = formValidation()

    const onSubmit = async () => {
      const response = await updateSkill(props.skill)
      if (typeof response !== 'undefined') {
        emit('refetch-data')
        emit('update:is-update-sidebar-active', false)
      }
    }

    return {
      refFormObserver,
      getValidationState,
      skillStatuses,
      resetForm,
      onSubmit,
    }
  },
}
</script>
<style scoped>
  .ql-container {
    min-height: 15rem;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .ql-editor {
    height: 100%;
    flex: 1;
    overflow-y: auto;
    width: 100%;
  }
</style>
