<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    width="50%"
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          Add New Plans
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Skill Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="catData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="arName"
            rules="required"
          >
            <b-form-group
              label="Arabic Name"
              label-for="arName"
            >
              <b-form-input
                id="name"
                v-model="catData.arName"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="userType"
            rules="required"
          >
            <b-form-group
              label="User Type"
              label-for="userType"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="catData.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                :reduce="(val) => val.value"
                :clearable="true"
                input-id="userType"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="price"
            rules="required"
          >
            <b-form-group
              label="Price"
              label-for="price"
            >
              <b-form-input
                id="price"
                v-model="catData.price"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="followUsers"
            rules="required"
          >
            <b-form-group
              label="Follow Users count"
              label-for="followUsers"
            >
              <b-form-input
                id="price"
                v-model="catData.followUsers"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="chatNumber"
            rules="required"
          >
            <b-form-group
              label="Chat number count"
              label-for="chatNumber"
            >
              <b-form-input
                id="price"
                v-model="catData.chatNumber"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="displayOrder"
            rules="required"
          >
            <b-form-group
              label="Display order"
              label-for="displayOrder"
            >
              <b-form-input
                id="price"
                v-model="catData.displayOrder"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Skill Slug -->
          <validation-provider
            #default="validationContext"
            name="content"
            rules="required"
          >
            <b-form-group
              label="Content"
              label-for="content"
            >
              <quill-editor
                v-model="catData.content"
                required
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="arContent"
            rules="required"
          >
            <b-form-group
              label="Arabic Content"
              label-for="arContent"
            >
              <quill-editor
                v-model="catData.arContent"
                required
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="currency"
            rules="required"
          >
            <b-form-group
              label="Currency"
              label-for="currency"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="catData.currency"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="getCountriesCurrency"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="currency"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="image"
            rules="required"
          >
            <b-form-group
              label="Image"
              label-for="currency"
              :state="getValidationState(validationContext)"
            >
              <b-form-file
                v-model="catData.image"
                required
                accept="image/jpeg, image/png, image/gif"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                :multiple="false"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="badge"
            rules="required"
          >
            <b-form-group
              label="Badge"
              label-for="badge"
              :state="getValidationState(validationContext)"
            >
              <b-form-file
                v-model="catData.badge"
                required
                accept="image/jpeg, image/png, image/gif"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                :multiple="false"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="auction"
            rules="required"
          >
            <b-form-group
              label=""
              label-for="contactDetails"
              :state="getValidationState(validationContext)"
            >
              <b-form-checkbox
                v-model="catData.contactDetails"
                checked="false"
                name="check-button"

                switch
                inline
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                input-id="contactDetails"
              >
                Contact Details
              </b-form-checkbox>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="auction"
            rules="required"
          >
            <b-form-group
              label=""
              label-for="Auction"
              :state="getValidationState(validationContext)"
            >
              <b-form-checkbox
                v-model="catData.auction"
                checked="false"
                name="check-button"

                switch
                inline
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                input-id="auction"
              >
                Auction
              </b-form-checkbox>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="timeLine"
            rules="required"
          >
            <b-form-group
              label=""
              label-for="timeLine"
              :state="getValidationState(validationContext)"
            >
              <b-form-checkbox
                v-model="catData.timeLine"
                checked="false"
                name="check-button"

                switch
                inline
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                input-id="timeLine"
              >
                TimeLine
              </b-form-checkbox>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="timeLineSuggestions"
            rules="required"
          >
            <b-form-group
              label=""
              label-for="timeLine"
              :state="getValidationState(validationContext)"
            >
              <b-form-checkbox
                v-model="catData.timeLineSuggestions"
                checked="false"
                name="check-button"

                switch
                inline
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                input-id="timeLineSuggestions"
              >
                TimeLine Suggestions
              </b-form-checkbox>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="newsLetter"
            rules="required"
          >
            <b-form-group
              label=""
              label-for="newsLetter"
              :state="getValidationState(validationContext)"
            >
              <b-form-checkbox
                v-model="catData.newsLetter"
                checked="false"
                name="check-button"

                switch
                inline
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                input-id="newsLetter"
              >
                NewsLetter
              </b-form-checkbox>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="forum"
            rules="required"
          >
            <b-form-group
              label=""
              label-for="forum"
              :state="getValidationState(validationContext)"
            >
              <b-form-checkbox
                v-model="catData.forum"
                checked="false"
                name="check-button"

                switch
                inline
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                input-id="forum"
              >
                Forum
              </b-form-checkbox>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="events"
            rules="required"
          >
            <b-form-group
              label=""
              label-for="events"
              :state="getValidationState(validationContext)"
            >
              <b-form-checkbox
                v-model="catData.events"
                checked="false"
                name="check-button"

                switch
                inline
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                input-id="events"
              >
                Events
              </b-form-checkbox>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="ads"
            rules="required"
          >
            <b-form-group
              label=""
              label-for="ads"
              :state="getValidationState(validationContext)"
            >
              <b-form-checkbox
                v-model="catData.ads"
                checked="false"
                name="check-button"

                switch
                inline
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                input-id="ads"
              >
                Ads
              </b-form-checkbox>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormTextarea,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import useSkillsList from './usePlansList'

import '@riophae/vue-treeselect/dist/vue-treeselect.css'
// import currencies from "@/store/country/currencies.json"

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    vSelect,
    quillEditor,
    BFormFile,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },

    skillStatuses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      typeOptions: [
        { label: 'FreeLancer', value: 'freelancer' },
        { label: 'Investor', value: 'investor' },
        { label: 'Company', value: 'company' },
        { label: 'Advisor', value: 'advisor' },
        { label: 'Broker', value: 'broker' },
        { label: 'Partner', value: 'partner' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getCountriesCurrency: 'country/getCountriesCurrency',
    }),
  },
  methods: {
    ...mapActions({
      fetchCountries: 'country/retreiveCountries',
    }),
  },
  created() {
    this.fetchCountries()
  },
  setup(props, { emit }) {
    const { addSkill } = useSkillsList()
    const blankSkillData = {
      name: '',
      content: '',
      arName: '',
      arContent: '',
      currency: null,
      price: null,
      image: null,
      chatNumber: null,
      auction: false,
      contactDetails: false,
      timeLine: false,
      timeLineSuggestions: false,
      newsLetter: false,
      forum: false,
      events: false,
      ads: false,
      followUsers: null,
      type: null,
      displayOrder: 0,
      badge: null,
    }
    const catData = ref(JSON.parse(JSON.stringify(blankSkillData)))
    const resetCatData = () => {
      catData.value = JSON.parse(JSON.stringify(blankSkillData))
    }

    const onSubmit = async () => {
      const response = await addSkill(catData.value)
      if (typeof response !== 'undefined') {
        emit('refetch-data')
        emit('update:is-add-new-sidebar-active', false)
      }
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetCatData)

    return {
      catData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.ql-container {
  min-height: 15rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}
#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
