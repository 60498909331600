import { ref, watch, computed } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default function useSkillsList() {
  const toast = useToast();
  const refSkillsTable = ref(null);

  const perPageOptions = [10, 25, 50, 100];
  const perPage = ref(10);
  const searchQuery = ref("");
  const totalSkills = ref(0);
  const currentPage = ref(1);
  const isAddNewSidebarVisible = ref(false);
  const isUpdateSidebarActive = ref(false);
  const isLoading = ref(false);

  const skillStatuses = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const fetchSkills = async () => {
    try {
      const response = await axios.get("plans");
      const { data } = response;
      totalSkills.value = data.count;
      return data.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: "Error fetching plans list",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  };
  const refetchData = () => {
    refSkillsTable.value.refresh();
  };

  const addSkill = async (data) => {
    try {
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("arName", data.arName);
      formData.append("content", data.content);
      formData.append("arContent", data.arContent);
      formData.append("image", data.image);
      formData.append("badge", data.badge);
      formData.append("price", data.price);
      formData.append("currency", data.currency.toLowerCase());
      formData.append("chatNumber", data.chatNumber);
      formData.append("auction", data.auction);
      formData.append("contactDetails", data.contactDetails);
      formData.append("timeLine", data.timeLine);
      formData.append("timeLineSuggestions", data.timeLineSuggestions);
      formData.append("newsLetter", data.newsLetter);
      formData.append("forum", data.forum);
      formData.append("events", data.events);
      formData.append("ads", data.ads);
      formData.append("followUsers", data.followUsers);
      formData.append("type", data.type);
      formData.append("displayOrder", data.displayOrder);
      const resp = await axios
        .post("plans/", formData)
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title:
                typeof error.response !== "undefined"
                  ? error.response.data.error.message
                  : error.message || "Unable to save new plans",
              icon: "AlertTrainingIcon",
              variant: "danger",
            },
          });
        });
      return resp;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            typeof error.response !== "undefined"
              ? error.response.data.error.message
              : error.message || "Unable to save new plans",
          icon: "AlertTrainingIcon",
          variant: "danger",
        },
      });
    }
  };

  const getSkillData = async (skillID) => {
    try {
      const response = await axios.get(`plans/${skillID}`);
      return response.data.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            typeof error.response !== "undefined"
              ? error.response.data.error.message
              : error.message || "Unable to save new plans",
          icon: "AlertTrainingIcon",
          variant: "danger",
        },
      });
    }
  };

  const updateSkill = async (skill) => {
    try {
      const formData = new FormData();
      formData.append("name", skill.name);
      formData.append("id", skill._id);
      formData.append("arName", skill.arName);
      formData.append("content", skill.content);
      formData.append("arContent", skill.arContent);
      if (skill.badge) {
        formData.append("image", skill.image);
      }
      if (skill.badge) {
        formData.append("badge", skill.badge);
      }

      formData.append("price", skill.price);
      formData.append("currency", skill.currency.toLowerCase());
      formData.append("chatNumber", skill.chatNumber);
      formData.append("auction", skill.auction);
      formData.append("contactDetails", skill.contactDetails);
      formData.append("timeLine", skill.timeLine);
      formData.append("timeLineSuggestions", skill.timeLineSuggestions);
      formData.append("newsLetter", skill.newsLetter);
      formData.append("forum", skill.forum);
      formData.append("events", skill.events);
      formData.append("ads", skill.ads);
      formData.append("followUsers", skill.followUsers);
      formData.append("type", skill.type);
      formData.append("displayOrder", skill.displayOrder);
      const response = await axios.put("plans/", formData);

      toast({
        component: ToastificationContent,
        props: {
          title: response.data.message,
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
      return response;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            typeof error.response !== "undefined"
              ? error.response.data.error.message
              : error.message || "Unable to update the permission",
          icon: "AlertTrainingIcon",
          variant: "danger",
        },
      });
    }
  };
  const deletePermission = async (skillID) => {
    try {
      const response = await axios.delete(`plans/${skillID}`);
      toast({
        component: ToastificationContent,
        props: {
          title: response.data.message,
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
      refetchData();
      return response;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title:
            typeof error.response !== "undefined"
              ? error.response.data.error.message
              : error.message || "Unable to delete the plans",
          icon: "AlertTrainingIcon",
          variant: "danger",
        },
      });
    }
  };

  const tableColumns = [
    { key: "name", sortable: true },
    { key: "type", sortable: false },
    { key: "currency", sortable: true },
    { key: "price", sortable: true },
    { key: "actions" },
  ];

  const resolveSkillStatusVariant = (status) => {
    if (status == 1) return "success";
    return "danger";
  };

  const dataMeta = computed(() => {
    const localItemsCount = refSkillsTable.value ? refSkillsTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSkills.value,
    };
  });

  // let's watch if there is something changed in the skill
  watch([perPage, searchQuery, currentPage], () => {
    refetchData();
  });

  return {
    perPage,
    perPageOptions,
    searchQuery,
    fetchSkills,
    tableColumns,
    deletePermission,
    resolveSkillStatusVariant,
    refSkillsTable,
    totalSkills,
    currentPage,
    dataMeta,
    isAddNewSidebarVisible,
    isUpdateSidebarActive,
    skillStatuses,
    addSkill,
    refetchData,
    getSkillData,
    updateSkill,
  };
}
